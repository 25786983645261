export default [
  {
    path: "/newsList", // 新闻列表
    name: "newsList",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/News/NewsList/index.vue"
      ),
  },
  {
    path: "/newsDetail", // 新闻列表
    name: "newsDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/News/NewsDetail/index.vue"
      ),
  },
];
