import axios from "axios";
import store from "@/store/index";
import router from "@/router/index";
import { SET_TOKEN } from "@/store/types";
import { Message } from "element-ui";
import { APIPREFIX } from "@/api/constant";
axios.defaults.timeout = 10e5;
axios.defaults.xsrfHeaderName = "x-csrf-token";
axios.defaults.xsrfCookieName = "csrfToken";
axios.defaults.withCredentials = true;
const Status_Nologin = 10002;
function request({ data, url, ...rest }) {
  const config = {
    ...rest,
    url: APIPREFIX + url,
    headers: {
      ...rest.headers,
      token: store.state.auth.token,
    },
  };
  config[rest.method === "get" ? "params" : "data"] = data;
  return axios
    .create()
    .request(config)
    .then(({ data }) => {
      if (data && data.status !== 0) {
        if (data.status === Status_Nologin) {
          store.commit(SET_TOKEN, "");
          router.push({ name: "home" });
        }
        Message.error({ type: "error", message: data.msg });
        return Promise.reject(data);
      }
      return data.data;
    })
    .catch((res) => {
      if (res.message) Message.error({ type: "error", message: res.message });
      return Promise.reject(res);
    });
}

export const axiosRequest = {
  get(url, data, config = {}) {
    return request({
      url,
      data,
      method: "get",
      ...config,
    });
  },
  post(url, data, config = {}) {
    return request({
      url,
      data,
      method: "post",
      ...config,
    });
  },
  // delete(url, data, config = {}) {
  //   return request({
  //     url,
  //     data,
  //     method: "delete",
  //     ...config,
  //   });
  // },
};
export default {
  install(Vue) {
    Object.assign(Vue.prototype, {
      $axios: axiosRequest,
    });
  },
};
