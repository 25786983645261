export default [
  // 商城订单管理
  {
    path: "/userCenter/mallBillManage",
    name: "mallBillManage", // 发票管理
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/MallManage/BillManage/index.vue"
      ),
  },
  {
    path: "/userCenter/strictMallDeed",
    name: "strictMallDeed", // 严选商城委托管理
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/MallManage/StrictMallDeed/index.vue"
      ),
  },
  {
    path: "/userCenter/strictServiceManage",
    name: "strictServiceManage", // 严选商城服务管理
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/MallManage/StrictServiceManage/index.vue"
      ),
  },
  {
    path: "/userCenter/standardManage",
    name: "standardManage", // 严选商城标准管理
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/MallManage/StandardManage/index.vue"
      ),
  },
  {
    path: "/userCenter/superMallOrderManage",
    name: "superMallOrderManage", // 优选商城订单管理
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/MallManage/SuperMallOrderManage/index.vue"
      ),
  },
  {
    path: "/userCenter/superMallGoodsManage",
    name: "superMallGoodsManage", // 优选商城商品管理
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/MallManage/SuperMallGoodsManage/index.vue"
      ),
  },
];
