import oss from "./oss";
import jump from "./jump";
import request from "./request";
// import elementComponent from "./elementComponent";
// import commonComponent from "./commonComponent";
export default {
  install(Vue) {
    Vue.use(oss);
    Vue.use(jump);
    Vue.use(request);
    // Vue.use(elementComponent);
    // Vue.use(commonComponent);
  },
};
