<template>
  <div class="flex flex-row phone-codeWrap">
    <el-input
      v-model="smsCode"
      placeholder="请输入短信验证码"
      class="flex-1 phone-smsCode"
    ></el-input>
    <div class="phone-tip pointer" @click="getSmsCode">
      {{ isCountdown ? count + "s" : "获取验证码" }}
    </div>
  </div>
</template>
<script>
import { getPhoneCode } from "@/api/login";
import { Base64 } from "js-base64";
export default {
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    phone: {
      type: [Number, String],
      default: null,
    },
    codeType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isCountdown: false,
      count: 60,
    };
  },
  computed: {
    smsCode: {
      get: function () {
        return this.value || "";
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    async getSmsCode() {
      if (this.isCountdown) {
        return;
      }
      if (!this.phone) {
        this.$message.warning("请输入手机号码");
        return;
      }

      // 验证类别（1-用户注册；2-用户登录；3-找回密码；4-修改手机号码）
      const { sendSMSStatus, smsSendMsg } = await this.$axios.post(
        getPhoneCode,
        {
          userPhone: Base64.toBase64(this.phone),
          validType: this.codeType,
        }
      );
      this.$message[sendSMSStatus ? "success" : "error"](smsSendMsg || "");
      if (!sendSMSStatus) return;
      this.isCountdown = true;
      const second = 1000;
      this.count = 60;
      const timeId = setInterval(() => {
        this.count--;
        if (this.count === 0) {
          this.isCountdown = false;
          clearInterval(timeId);
        }
      }, second);
    },
  },
};
</script>
<style lang="scss" scoped>
.phone {
  &-codeWrap {
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding-right: 10px;
  }
  &-smsCode {
    /deep/ input {
      border: none;
    }
  }
  &-tip {
    color: #3073f4;
  }
}
</style>
