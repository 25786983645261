export const SET_TOKEN = "SET_TOKEN";
export const SET_USERBASEINFO = "SET_USERBASEINFO"; // 用户基本信息
export const SET_NAV = "SET_NAV"; // 选中导航
export const SET_DICTS = "SET_DICTS"; // 数据字典
export const SET_SHOWLOGIN = "SET_SHOWLOGIN"; // 展示登录模块
export const SET_SHOOSEGOODS = "SET_SHOOSEGOODS"; // 提交订单时选择商品
export const SET_PLATFORM_BASE = "SET_PLATFORM_BASE"; // 平台基础配置信息
export const SET_PLATFORM_BASENAV = "SET_PLATFORM_BASENAV"; // 平台导航
export const SET_PLATFORM_MORENAV = "SET_PLATFORM_MORENAV"; // 平台折叠导航
export const SET_MSG_UNREAD_COUNT = "SET_MSG_UNREAD_COUNT"; // 消息未读数
export const SET_DEEDPRINT = "SET_DEEDPRINT"; // 打印委托单数据临时存储
