import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/style/element-variables.scss";
import Plugins from "@/plugins";
import HeadBar from "@/components/HeadBar/index";
import HeadNav from "@/components/HeadNav/index";
import FootBar from "@/components/FootBar/index";
import EditorView from "@/components/EditorView/index";
import OrderProgress from "@/components/OrderProgress/index";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.use(Plugins);
Vue.use(ElementUI);
Vue.component("HeadBar", HeadBar);
Vue.component("HeadNav", HeadNav);
Vue.component("FootBar", FootBar);
Vue.component("OrderProgress", OrderProgress);
Vue.component("EditorView", EditorView);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
