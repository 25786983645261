<template>
  <div class="login">
    <div class="tab">
      <span
        v-for="(item, index) in tabs"
        :key="index"
        :class="{ active: tabIndex === index }"
        class="pointer"
        @click="tabIndex = index"
        >{{ item.text }}</span
      >
    </div>
    <div class="login-con">
      <Account
        v-if="tabs[tabIndex].value === 'account'"
        @loginSuccess="$emit('loginSuccess', $event)"
      />
      <PhoneLogin v-else @loginSuccess="$emit('loginSuccess', $event)" />
      <div class="login-label">
        <span @click="$emit('toRegister')">免费注册</span>
        <el-divider direction="vertical"></el-divider>
        <span @click="$emit('forgetPwd')">忘记密码</span>
      </div>
    </div>
  </div>
</template>
<script>
import Account from "./Account.vue";
import PhoneLogin from "./Phone.vue";
export default {
  components: {
    Account,
    PhoneLogin,
  },
  data() {
    return {
      tabIndex: 0,
      tabs: [
        { text: "账号登录", value: "account" },
        { text: "手机登录", value: "phone" },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.tab {
  text-align: center;
  margin-bottom: 30px;
  span {
    font-size: 16px;
    &.active {
      color: #3073f4;
      font-weight: bold;
      position: relative;
      &::before {
        display: inline-block;
        position: absolute;
        content: "";
        width: 50%;
        height: 3px;
        background: #3073f4;
        left: 25%;
        bottom: -6px;
        border-radius: 2px;
      }
    }
    &:first-child {
      margin-right: 30px;
    }
  }
}
.login {
  &-con {
    padding: 0 15px;
  }

  &-label {
    text-align: right;
    color: $theme-color;
    > span {
      cursor: pointer;
    }
  }
}
</style>
