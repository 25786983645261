<template>
  <div class="ql-editor" v-html="data"></div>
</template>
<script>
export default {
  props: {
    data: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.ql-editor {
  /deep/ img {
    max-width: 100%;
  }
}
</style>
