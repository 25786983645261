export default [
  {
    path: "/userCenter/account",
    name: "account", // 账号信息
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/UserCenterChild/Account/index.vue"
      ),
  },
  {
    path: "/userCenter/myDemand",
    name: "myDemand", // 我的需求单
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/UserCenterChild/MyDemand/index.vue"
      ),
  },
  {
    path: "/userCenter/myMessage",
    name: "myMessage", // 我的消息
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/UserCenterChild/MyMessage/index.vue"
      ),
  },
  {
    path: "/userCenter/myCoupons",
    name: "myCoupons", // 我的优惠券
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/UserCenterChild/MyCoupons/index.vue"
      ),
  },
  {
    path: "/userCenter/strictMallTrustDeed",
    name: "strictMallTrustDeed", // 严选商城委托单
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/UserCenterChild/StrictMallTrustDeed/index.vue"
      ),
  },
  {
    path: "/userCenter/superMallOrder",
    name: "superMallOrder", // 优选商城订单
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/UserCenterChild/SuperMallOrder/index.vue"
      ),
  },
  {
    path: "/userCenter/billManage",
    name: "billManage", // 发票管理
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/UserCenterChild/BillManage/index.vue"
      ),
  },
  {
    path: "/userCenter/addressManage",
    name: "addressManage", // 地址管理
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/UserCenterChild/AddressManage/index.vue"
      ),
  },
  {
    path: "/userCenter/onlineTrain",
    name: "userCenterOnlineTrain", // 地址管理
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/UserCenterChild/OnlineTrain/index.vue"
      ),
  },
  {
    path: "/userCenter/myApplyEstimate",
    name: "myApplyEstimate", // 我申请的专家评估
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/UserCenterChild/MyApplyEstimate/index.vue"
      ),
  },
  {
    path: "/userCenter/chartroom",
    name: "chartroom", // 管理员客服中心
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Chartroom/index.vue"),
  },
  {
    path: "/userCenter/meettingMange",
    name: "meettingMange", // 会议
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/UserCenterChild/MeetingManage/index.vue"
      ),
  },
];
