export default [
  {
    path: "/releaseReq", // 发布需求
    name: "releaseReq",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ReleaseRequirements/index.vue"
      ),
  },
  {
    path: "/demandHall", // 需求大厅
    name: "demandHall",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DemandHall/index.vue"),
  },
  {
    path: "/demandDetail", // 需求详细
    name: "demandDetail",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DemandDetail/index.vue"),
  },
  {
    path: "/capabilityHall", // 能力大厅
    name: "capabilityHall",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/CapabilityHall/index.vue"
      ),
  },
  {
    path: "/checkProjectDetail", // 检测项目详情
    name: "checkProjectDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/CheckProjectDetail/index.vue"
      ),
  },
];
