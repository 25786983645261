import Vue from "vue";
import VueRouter from "vue-router";
import UserCenter from "./userCenter";
import Hall from "./hall";
import Mall from "./mall";
import Laboratory from "./laboratory";
import OnlineTrain from "./onlineTrain"; // 在线培训
import AllianceZone from "./allianceZone"; // 联盟专区
import Activity from "./activity"; // 优惠活动
import Experts from "./experts";
import Teacher from "./teacher";
import News from "./news";
import Pay from "./pay";
import Journal from "./journal";
import { axiosRequest } from "@/plugins/request";
import { buryPoint } from "@/api/common";
import store from "@/store/index";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home", // 首页
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home/index.vue"),
    meta: {
      eventId: "W-P-1-V1",
      eventName: "首页曝光",
      module: "首页",
    },
  },
  {
    path: "/register", // 注册
    name: "register",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Register/index.vue"),
  },
  {
    path: "/wholeSearch", // 注册
    name: "wholeSearch",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/WholeSearch/index.vue"),
  },
  {
    path: "/entRegister", // 企业注册
    name: "entRegister",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/EntRegister/index.vue"),
  },
  {
    path: "/baseIntroduction", // 基地介绍
    name: "baseIntroduction",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/BaseIntroduction/index.vue"
      ),
  },
  {
    path: "/contactUs", // 联系我们
    name: "contactUs",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ContactUs/index.vue"),
  },
  ...Laboratory,
  ...Mall,
  ...Hall,
  ...UserCenter,
  ...OnlineTrain,
  ...AllianceZone,
  ...Experts,
  ...News,
  ...Pay,
  ...Activity,
  ...Journal,
  ...Teacher,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    // return 期望滚动到哪个的位置
    return { x: 0, y: 0 };
  },
});
router.beforeEach((to, from, next) => {
  const { meta } = to; // 当前路由
  if (meta && meta.eventId) {
    const params = {
      ...meta,
      currentUrl: to.fullPath,
      currentUrlAlias: to.name,
      referrerUrl: from.fullPath,
      referrerUrlAlias: from.name,
      userId: store.state.auth.userBaseInfo.userId,
    };
    axiosRequest.post(buryPoint, params);
  }
  next();
});
export default router;
