export default {
  token: (state) => state.auth.token,
  showLogin: (state) => state.auth.showLogin,
  userBaseInfo: (state) => state.auth.userBaseInfo,
  nav: (state) => +state.common.nav,
  platform: (state) => state.common.platform,
  pageBaseNavs: (state) => state.common.pageBaseNavs,
  pageMoreNavs: (state) => state.common.pageMoreNavs,
  msgUnread: (state) => state.auth.msgUnread,
  deedPrint: (state) => state.common.deedPrint,
};
