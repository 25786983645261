export default [
  {
    path: "/onlineTrain",
    name: "onlineTrain", // 在线培训
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/OnlineTrain/OnlineList/index.vue"
      ),
  },
  {
    path: "/onlineTrainDetail",
    name: "onlineTrainDetail", // 在线培训详情页
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/OnlineTrain/TrainDetail/index.vue"
      ),
  },
  {
    path: "/onlineVideoView",
    name: "onlineVideoView", // 在线培训视频观看页
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/OnlineTrain/VideoView/index.vue"
      ),
  },
  {
    path: "/trainOrderConfirm",
    name: "trainOrderConfirm", // 在线培训视频观看页
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/OnlineTrain/OrderConfirm/index.vue"
      ),
  },
];
