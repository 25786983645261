<template>
  <div class="login">
    <div class="login-title">忘记密码</div>
    <div class="login-con">
      <el-form :model="form" class="demo-form-inline">
        <el-form-item label="">
          <el-input
            v-model="form.userPhone"
            placeholder="请输入注册时使用的手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <PhoneCode
            v-model="form.smsCode"
            :phone="form.userPhone"
            :codeType="setPwdCodeType"
          />
        </el-form-item>
        <el-form-item label="">
          <el-input
            v-model="form.password"
            placeholder="输入新密码"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input
            v-model="form.passwordAgain"
            placeholder="确认密码"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="login-btn" @click="resetPwd"
            >重置密码</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { SYSCODE_TYPE } from "@/constant/index";
import PhoneCode from "@/components/PhoneCode/index";
import { setPwdByPhone } from "@/api/login";
import { Base64 } from "js-base64";
export default {
  components: {
    PhoneCode,
  },
  data() {
    return {
      form: { userPhone: "", smsCode: "", password: "", passwordAgain: "" },
      setPwdCodeType: SYSCODE_TYPE.setPwd,
    };
  },
  methods: {
    async resetPwd() {
      const { userPhone, smsCode, password, passwordAgain } = this.form;
      if (password !== passwordAgain) {
        this.$message.error("新密码和确认密码不一致,请重新输入");
        return;
      }
      if (userPhone && smsCode && password && passwordAgain) {
        await this.$axios.post(setPwdByPhone, {
          userPhone: Base64.toBase64(userPhone),
          smsCode: Base64.toBase64(smsCode),
          password: Base64.toBase64(password),
        });
        this.$message.success("设置密码成功");
        this.$emit("setPwdSuccess");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variable";
.login {
  &-title {
    text-align: center;
    font-size: 1.14rem;
    color: $theme-color;
    font-weight: bold;
    margin-bottom: 15px;
    padding-top: 10px;
  }
  &-con {
    padding: 0 20px;
  }
  &-btn {
    width: 100%;
    border-radius: 50px;
    margin-bottom: 10px;
  }
}
.messageCode {
  margin-left: 10px;
  width: 140px;
  /deep/ .el-button {
    width: 100%;
  }
}
</style>
