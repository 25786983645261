// 登录注册

export const login = `/api/unoauth/user/verifyLoginUser`; // 登录
export const logout = `/api/unoauth/user/logout`; // 退出登录
export const userBaseInfo = `/api/oauth/user/loginUserInfo`; // 获取用户基本信息

export const userRegister = `/api/unoauth/user/registerPlatformUser`; //注册用户

export const updateUserInfo = `/api/oauth/user/updateLoginUserInfo`; // 修改个人信息

export const fetchImgCode = `/api/unoauth/user/captchaImage`; // 获取图形验证码接口

export const phoneLogin = `/api/unoauth/user/verifyLoginUserOfValid`; // 手机号码+验证码登录

export const getPhoneCode = `/api/unoauth/user/sendValidCodeMsg`; // 获取手机验证码

export const setPwdByPhone = `/api/unoauth/user/verifyValidOfForgetPassword`; // 找回（忘记）密码短信验证码校验
