export default [
  {
    path: "/teacherList", // 讲师
    name: "teacherList",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Teacher/TeacherList/index.vue"
      ),
  },
  {
    path: "/teacherDetail", // 讲师详情
    name: "teacherDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Teacher/TeacherDetail/index.vue"
      ),
  },
];
