// import Cookies from "js-cookie";
import { userBaseInfo, logout } from "@/api/login";
import { getUserMsgUnreadCount } from "@/api/userCenter";
import { axiosRequest } from "@/plugins/request";
import router from "@/router/index";
import {
  SET_TOKEN,
  SET_USERBASEINFO,
  SET_SHOWLOGIN,
  SET_MSG_UNREAD_COUNT,
} from "../types";
// import cookieKeys from "../cookieKey";

export default {
  state: {
    token: "",
    userBaseInfo: {
      // userId: 1000001,
      // userRealName: "咨询者",
    },
    showLogin: false,
    msgUnread: 0, // 消息未读数
  },
  mutations: {
    [SET_TOKEN](state, token) {
      state.token = token;
    },
    [SET_USERBASEINFO](state, userBaseInfo) {
      state.userBaseInfo = userBaseInfo;
    },
    [SET_SHOWLOGIN](state, showLogin) {
      state.showLogin = showLogin;
    },
    [SET_MSG_UNREAD_COUNT](state, data) {
      state.msgUnread = data;
    },
  },
  actions: {
    // 登录成功后
    LoginSuccess({ commit, dispatch }, token) {
      commit(SET_TOKEN, token);
      dispatch("GetUserBaseInfo");
      commit(SET_SHOWLOGIN, false);
      dispatch("GetUserMsgUnreadCount");
    },
    // 获取用户信息
    async GetUserBaseInfo({ commit }) {
      const data = await axiosRequest.get(userBaseInfo);
      commit(SET_USERBASEINFO, data);
    },
    // 获取消息未读数
    async GetUserMsgUnreadCount({ commit }) {
      const data = await axiosRequest.get(getUserMsgUnreadCount);
      commit(SET_MSG_UNREAD_COUNT, data);
    },
    // 退出登录
    async Logout({ commit }) {
      commit(SET_TOKEN, "");
      commit(SET_USERBASEINFO, {});
      await axiosRequest.post(logout).finally(() => {
        router.push({ name: "home" });
      });
    },
    login({ commit }) {
      commit(SET_SHOWLOGIN, true);
    },
  },
};
