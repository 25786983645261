import {
  SET_NAV,
  SET_DICTS,
  SET_PLATFORM_BASE,
  SET_PLATFORM_BASENAV,
  SET_PLATFORM_MORENAV,
  SET_DEEDPRINT,
} from "../types";
import { listDictData } from "@/api/common";
import { axiosRequest } from "@/plugins/request";
export default {
  state: {
    nav: "",
    dicts: {},
    platform: {},
    pageBaseNavs: [],
    pageMoreNavs: { bigMenu: [], smallMenu: [] },
    deedPrint: {}, // 打印委托单数据临时存储
  },
  mutations: {
    [SET_NAV](state, nav) {
      state.nav = nav;
    },
    [SET_DICTS](state, data) {
      state.dicts = data;
    },
    [SET_PLATFORM_BASE](state, data) {
      state.platform = data;
    },
    [SET_PLATFORM_BASENAV](state, data) {
      state.pageBaseNavs = data;
    },
    [SET_PLATFORM_MORENAV](state, data) {
      state.pageMoreNavs = data;
    },
    [SET_DEEDPRINT](state, data) {
      state.deedPrint = data;
    },
  },
  actions: {
    // 获取数据字典数据
    async getDictData({ commit, state }, key) {
      if (state[key]) return state[key];
      else {
        const data = await axiosRequest.get(listDictData, { dictType: key });
        commit(SET_DICTS, { ...state.dicts, [key]: data });
        return data;
      }
    },
  },
};
