export default [
  // 实验室管理
  {
    path: "/userCenter/labAccountManage",
    name: "labAccountManage", // 实验室账号管理
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/LabManage/AccountManage/index.vue"
      ),
  },
  {
    path: "/userCenter/labInfoManage",
    name: "labInfoManage", // 实验室信息管理
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/LabManage/LabInfoManage/index.vue"
      ),
  },
  {
    path: "/userCenter/abilityManage",
    name: "labAbilityManage", // 实验室能力维护
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/LabManage/AbilityManage/index.vue"
      ),
  },
  {
    path: "/userCenter/deviceManage",
    name: "labDeviceManage", // 实验室设备维护
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/LabManage/DeviceManage/index.vue"
      ),
  },
];
