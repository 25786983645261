export default [
  {
    path: "/mallStrictHome",
    name: "mallStrictHome", // 严选商城首页页
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/MallStrictHome/index.vue"
      ),
    meta: {
      eventId: "W-F-2-V1",
      eventName: "服务首页曝光",
      module: "检测服务",
    },
  },
  {
    path: "/mallStrict",
    name: "mallStrict", // 严选商城页
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MallStrict/index.vue"),
    meta: {
      eventId: "W-F-3-V1",
      eventName: "服务列表曝光",
      module: "检测服务",
    },
  },
  {
    path: "/deviceDetail", // 设备详情页
    name: "deviceDetail",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DeviceDetail/index.vue"),
    meta: {
      eventId: "W-F-4-V1",
      eventName: "服务详情曝光",
      module: "检测服务",
    },
  },
  {
    path: "/deedPrint", // 委托单打印
    name: "deedPrint",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DeedPrint/index.vue"),
  },
  {
    path: "/mallSuperHome",
    name: "mallSuperHome", // 优选商城首页
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/MallSuperHome/index.vue"
      ),
    meta: {
      eventId: "W-S-6-V1",
      eventName: "商品首页曝光",
      module: "商品商城",
    },
  },
  {
    path: "/mallSuper",
    name: "mallSuper", // 优选商城页
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MallSuper/index.vue"),
    meta: {
      eventId: "W-S-7-V1",
      eventName: "商品列表曝光",
      module: "商品商城",
    },
  },

  {
    path: "/mallSuperShop",
    name: "mallSuperShop", // 优选商城店铺页面
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/MallSuperShop/index.vue"
      ),
  },
  {
    path: "/goodsDetail",
    name: "goodsDetail", // 优选商城商品详情页
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/GoodDetail/index.vue"),
    meta: {
      eventId: "W-S-8-V1",
      eventName: "商品详情曝光",
      module: "商品商城",
    },
  },
  {
    path: "/deviceOrder", // 设备下单页面
    name: "deviceOrder",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DeviceOrder/index.vue"),
  },
  {
    path: "/goodsOrder", // 商品下单页面
    name: "goodsOrder",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/GoodOrder/index.vue"),
  },
  {
    path: "/goodsCart", // 购物车
    name: "goodsCart",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/GoodCart/index.vue"),
  },
];
