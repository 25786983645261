export default [
  {
    path: "/journalList", // 期刊列表
    name: "journalList",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Journal/JournalList/index.vue"
      ),
  },
  {
    path: "/journalDetail", // 期刊详情
    name: "journalDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Journal/JournalDetail/index.vue"
      ),
  },
];
