import Cookies from "js-cookie";
import storeKeys from "../localStorageKeys";
import cookieKeys from "../cookieKey";

export default function (store) {
  // 初始化，获取存储中值设置到store内
  Object.keys(storeKeys).forEach((mutation) => {
    const storeKey = storeKeys[mutation];
    const value = localStorage.getItem(storeKey);
    if (value) store.commit(mutation, value);
  });
  // 初始化，获取存储中值设置到store内
  Object.keys(cookieKeys).forEach((mutation) => {
    const storeKey = cookieKeys[mutation];
    const value = Cookies.get(storeKey);
    if (value) store.commit(mutation, value);
  });
  store.subscribe((mutation) => {
    const { type, payload } = mutation;
    const storeKey = storeKeys[type];
    if (storeKey) localStorage.setItem(storeKey, payload);
    const cookieKey = cookieKeys[type];
    // const oldData =  Cookies.get(cookieKey)
    if (cookieKey) Cookies.set(cookieKey, payload);
  });
}
